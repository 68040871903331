import '../scripts/common';
import Vue from 'vue';
import Helpers from '../scripts/helpers';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'whatwg-fetch';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import store from './store/store';
import Services from '../scripts/services/services';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './fontawesome';
import { capitalize, formatSize, integer, lowercase, truncate } from '../scripts/filters';
import * as moment from 'moment';
import { mapGetters } from 'vuex';
import filters from './filters/index'
import {setCookie} from "../scripts/cookies";
import {parseUtmFromUrl} from '../scripts/parseUtmFromUrl'

Vue.use(BootstrapVue);

//Font Awesome Icons
Vue.component('fa-icon', FontAwesomeIcon);

//Filters
Vue.filter('capitalize', capitalize);
Vue.filter('lowercase', lowercase);
Vue.filter('truncate', truncate);
Vue.filter('formatSize', formatSize);
Vue.filter('integer', integer);

// Boot the current Vue component
const root = document.getElementById('app');

store.commit('layout', JSON.parse(root.dataset.layout));
store.commit('env', JSON.parse(root.dataset.env));
store.commit('title', root.dataset.title);
store.commit('routes', JSON.parse(root.dataset.routes));

Services.instance().register('store', () => {
    return store;
});

Services.instance().register('event', () => {
    return new Vue();
});

moment.locale('ru');
Vue.mixin({
    methods: {
        preparePrice(number, decimals, dec_point, thousands_sep) {
            return Helpers.preparePrice(number, decimals, dec_point, thousands_sep);
        },
        preparePhone(str) {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');

            //Check if the input is of correct length
            let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

            if (match) {
                return '+' + match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5];
            }

            return '';
        },
        pluralForm(n, forms) {
            return Helpers.plural_form(n, forms);
        },
        strToList(str) {
            if (!str) {
                return [];
            }
            return String(str).split('\n');
        },
        route(name) {
            try {
                let UTMArray = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'],
                    utms = parseUtmFromUrl();
                if (Object.keys(utms).length !== 0) for (let utm in utms) if (UTMArray.includes(utm)) setCookie(utm, utms[utm], 365 * 5);
            } catch(e) {
                console.log(e)
            }

            return '/' + this.$store.state.routes[name].replace(/^\//, '');
        },
        datePrint(date) {
            return moment(date, "YYYY-MM-DD").format('LL');
        },
        datetimePrint(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('LLL');
        },
        printDoc(src) {
            let iframe = this._printIframe;
            if (!this._printIframe) {
                iframe = this._printIframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.onload = function() {
                    setTimeout(function() {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };
            }

            iframe.src = src;
        },
        canView(block) {
            return !!this.blockPermissions.view.includes(block);
        },
        canUpdate(block) {
            return !!this.blockPermissions.update.includes(block);
        },
    },
    computed: {
        ...mapGetters(['getRoute']),
        staticText() {
            return this.$store.state.layout.staticBlock;
        },
        isGuest() {
            return this.$store.state.layout.isGuest;
        },
        /** @return {Blocks} */
        blocks() {
            return this.$store.state.layout.blocks;
        },
        /** @return {BlockPermissions} */
        blockPermissions() {
            return this.$store.state.layout.blockPermissions;
        },
    },
});

/**
 @typedef Blocks
 @type {Object}
 @property {number} orders
 @property {number} claims
 @property {number} catalog
 @property {number} marketing
 @property {number} analytic
 @property {number} merchant
 @property {number} communications
 */
/**
 @typedef BlockPermissions
 @type {Object}
 @property {ViewBlockPermissions} view
 @property {UpdateBlockPermissions} update
 */
/**
 @typedef ViewBlockPermissions
 @type {Array}
 @property {number}
 */
/**
 @typedef UpdateBlockPermissions
 @type {Array}
 @property {number}
 */